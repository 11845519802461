@import url('https://fonts.googleapis.com/css2?family=Varela_Round');

* {
    font-family: "Varela_Round", Arial, Helvetica, sans-serif;
}
html {
    background: white !important;
}

#overlay {
    width: 100%;
    position: absolute; /*You may also use fixed depending on your needs.*/
    left: 0;
    top: 30px; /*If you want it at the bottom you probably want bottom:0 instead*/
    z-index: 999;
}

#overlay2 {
    position: absolute; /*You may also use fixed depending on your needs.*/
    /*If you want it at the bottom you probably want bottom:0 instead*/
    z-index: 999;
    left: 500px;
    height: 150px;
    bottom: 40px;
}


.swal2-popup #swal2-content {
    text-align: center;
    font-size: x-large;
    color: red;
    font-weight: 600;
}

/* You can add global styles to this file, and also import other style files */


.red{
    border: 5px solid red;
    border-radius: 7px;
}


.blue{
    border: 1px solid rgba(54, 55, 56, 0.65)

}

.mat-dialog-container {
   width:  400px!important;
}

.mat-icon {
    cursor: pointer;
}
.red2{
    color : red;
    font-weight: bolder;
    margin-left: 10px;
}


.redtext{
    color : red;
    font-weight: bolder;
}
.my-custom-class-one{
    background: red;
}

.blue3{
    background: #1976d2;
    color: white;

}

.blue2{


}

.solar {
    background: url('assets/img/solar.png');
    height: 20px;
    width: 20px;
    display: block;
    /* Other styles here */
}

.dataTables_wrapper div.dataTables_filter input {
    width: 300px !important;
}


mat-error{
    font-weight: 900;
    font-size: 15px;
    color: red;

}

.amcharts-export-menu{
    display: none;
}


.amcharts-main-div a{
    display: none!important;
}

.bmd-label-floating, .bmd-label-placeholder{

}
.pointer {cursor: pointer;
}

.mat-radio-label-content{
    white-space: pre-wrap !important;
}

 h1, h3, h2 {
    color: #0e0101 !important;
    font-weight: bold !important;
}

table{
    color: #0e0101 !important;
    font-weight: bold !important;
}

small, .small {

    font-weight: bold !important;

}
.text-muted, .bmd-help {
    color: #040e01 !important;
    font-weight: bold !important;
}

body {
    color: #0e0101 !important;
    font-weight: bold !important;
}


.close {
    font-size: 55px !important;
    line-height: 1 !important;
    color: white !important;
    opacity: 100 !important;
}

.bb {
    break-inside: avoid;
    margin-bottom: 20px;
}

.ais-Hits-list {
    margin-top: 0;
    margin-bottom: 1em
}

.ais-SearchBox {
    margin-bottom: 1em
}

.ais-Pagination {
    margin-top: 1em
}

.left-panel {
    float: left;
    width: 250px
}

.right-panel {
    margin-left: 260px
}

.ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto
}

.ais-Hits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem)
}

.table-wrapper {

}

.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.fl-table td, .fl-table th {
    /*text-align: center;*/
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.fl-table thead th {
    color: #ffffff;
    background: #4162AE;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

/* Responsive */

/*@media (max-width: 767px) {*/
/*    .fl-table {*/
/*        display: block;*/
/*        width: 100%;*/
/*    }*/
/*    .table-wrapper:before{*/
/*        content: "Scroll horizontally >";*/
/*        display: block;*/
/*        text-align: right;*/
/*        font-size: 11px;*/
/*        color: white;*/
/*        padding: 0 0 10px;*/
/*    }*/
/*    .fl-table thead, .fl-table tbody, .fl-table thead th {*/
/*        display: block;*/
/*    }*/
/*    .fl-table thead th:last-child{*/
/*        border-bottom: none;*/
/*    }*/
/*    .fl-table thead {*/
/*        float: left;*/
/*    }*/
/*    .fl-table tbody {*/
/*        width: auto;*/
/*        position: relative;*/
/*        overflow-x: auto;*/
/*    }*/
/*    .fl-table td, .fl-table th {*/
/*        padding: 20px .625em .625em .625em;*/
/*        height: 60px;*/
/*        vertical-align: middle;*/
/*        box-sizing: border-box;*/
/*        overflow-x: hidden;*/
/*        overflow-y: auto;*/
/*        width: 120px;*/
/*        font-size: 13px;*/
/*        text-overflow: ellipsis;*/
/*    }*/
/*    .fl-table thead th {*/
/*        text-align: left;*/
/*        border-bottom: 1px solid #f7f7f9;*/
/*    }*/
/*    .fl-table tbody tr {*/
/*        display: table-cell;*/
/*    }*/
/*    .fl-table tbody tr:nth-child(odd) {*/
/*        background: none;*/
/*    }*/
/*    .fl-table tr:nth-child(even) {*/
/*        background: transparent;*/
/*    }*/
/*    .fl-table tr td:nth-child(odd) {*/
/*        background: #F8F8F8;*/
/*        border-right: 1px solid #E6E4E4;*/
/*    }*/
/*    .fl-table tr td:nth-child(even) {*/
/*        border-right: 1px solid #E6E4E4;*/
/*    }*/
/*    .fl-table tbody td {*/
/*        display: block;*/
/*        text-align: center;*/
/*    }*/
/*}*/
